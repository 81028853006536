<template>
  <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
    <b-form role="form" @submit.prevent="handleSubmit(() => { form.submit() })">
      <base-input alternative
                  name="name"
                  label="Name"
                  :rules="{ required: true }"
                  placeholder="Client Name"
                  v-model="form.name">
      </base-input>
      <base-button type="primary" native-type="submit" :loading="form.busy">Save</base-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { Form, Http } from "@services"
export default {
  props: ['client'],
  data() {
    const initialData = {
      name: this.client ? this.client.name : '',
    };
    return {
        form: new Form(initialData, async (form) => {
          const method = this.updating ? 'patch' : 'post'
          const id = this.client ? this.client.id : null;
          const { data } = await Http[method](`/clients/${id || ''}`, form);
          this.$emit('success', data);
        }, ({ errors }) => {
          this.$emit('failed', errors);
          this.$refs.formValidator.setErrors(errors);
        }),
        updating: !!this.client
    }
  }
}
</script>
