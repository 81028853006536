<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-3">
              <div class="text-center py-4">
                <img src="/img/brand/logo.png" alt="Cortex Logo" />
              </div>
              <base-alert v-if="message" :type="messageType">
                {{ message }}
              </base-alert>
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon" @click="redirectToGoogle">
                  <span class="btn-inner--icon"><img src="/img/icons/common/google.svg" alt="GSuite Login"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-3">
              <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(() => { form.submit() })">
                  <base-input alternative
                              class="mb-3"
                              name="email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="form.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="form.password">
                  </base-input>

                  <b-form-checkbox v-model="form.remember">Remember me</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4" :loading="form.busy">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="12">
              <router-link :to="{name: 'Forgot Password'}" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Form, Http } from "@services"
  export default {
    data() {
      const initialData = {
        'email': '',
        'password': '',
        'remember': null
      };
      return {
        message: this.$route.params.message,
        messageType: 'success',
        form: new Form(initialData, async form => {
          await this.signIn(form.export());
          if (this.authenticated) {
            await this.$router.replace({ name: 'Dashboard' })
          }
        }, async ({ errors }) => {
          this.$refs.formValidator.setErrors(errors);
        })
      };
    },
    async mounted() {
      // If we have a code here, we've received a callback from Google.
      if (this.$route.query.code) {
        await this.handleOAuthCallback();
      }
    },
    methods: {
      ...mapActions({
        signIn: 'auth/login',
        refreshUser: 'auth/refreshUser'
      }),
      async handleOAuthCallback() {
        this.message = 'Logging in...';

        try {
          await Http.get(`/auth/callback${window.document.location.search}`)
          await this.refreshUser()
          await this.$router.replace({ name: 'Dashboard' })
        } catch ({ response }) {
          this.messageType = 'danger';
          this.message = response.data.message || 'Something went wrong during authentication.';
        }
      },
      async redirectToGoogle() {
        const { url } = await Http.get('/login/google')
        window.location = url;
      }
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
      })
    }
  };
</script>
