<template>
  <modal v-bind="$attrs" v-on="$listeners">
    <h6 slot="header" class="modal-title">{{ title }}</h6>
    <slot>
      <p>Please confirm your password to continue.</p>
    </slot>
    <validation-observer ref="formValidator">
      <form class="needs-validation" @submit.prevent="onSubmit">
        <base-input name="password"
                    placeholder="Password"
                    success-message=""
                    rules="required"
                    type="password"
                    v-model="form.password">
        </base-input>
      </form>
    </validation-observer>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="closeModal()">Close</base-button>
      <base-button :type="buttonType" class="text-uppercase" :loading="form.busy" @click="onSubmit">{{ buttonText }}</base-button>
    </template>

  </modal>
</template>
<script>
import {Form, Http} from "@services"

export default {
  name: 'ModalConfirmPassword',
  props: {
    url: String,
    title: {
      type: String,
      default: "Confirm Password"
    },
    buttonText: {
      type: String,
      default: "Submit"
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    method: {
      type: String,
      default: "delete"
    }
  },
  data() {
    const initialData = {
      password: ''
    };
    return {
      form: new Form(initialData, async form => {
        await Http.post(this.url, {...form.export(), _method: this.method });
        this.$emit('success');
        this.closeModal();
      }, async ({ response, errors }) => {
        this.$emit('failed', response);
        this.$refs.formValidator.setErrors(errors);
      })
    }
  },
  methods: {
    onSubmit: function() {
      this.$refs.formValidator.validate().then(success => {
        if (success) {
          return this.form.submit();
        }
      });
    },
    closeModal: function() {
      this.$emit("update:show", false);
      this.$emit("close");
    }
  }

}
</script>
