<template>
  <div>
    <card>
      <h3 slot="header" class="mb-0">Browser Sessions</h3>
      <b-row align-v="start">
        <b-col sm="4">
          <small>Manage and logout your active sessions on other browsers and devices.</small>
        </b-col>
        <b-col sm="8">
          <small>If necessary, you may logout of all of your other browser sessions across all of your devices. If you
            feel your account has been compromised, you should also update your password.</small>
          <div class="mt-3 d-flex" v-for="(session, index) in sessions" :key="index">
            <div style="font-size: 2rem">
              <span v-if="session.agent.is_desktop"><i class="ni ni-tv-2"></i></span>
              <span v-else><i class="ni ni-mobile-button"></i></span>
            </div>
            <div class="ml-3 text-sm">
              <div>{{ session.agent.platform }} - {{ session.agent.browser }}</div>
              <div>
                <small>
                  {{ session.ip_address }}
                  <span class="text-success" v-if="session.is_current_device">This device.</span>
                  <span v-else>{{ session.last_active }}</span>
                </small>
              </div>
            </div>
          </div>
          <base-button v-if="hasOtherSessions" type="outline-primary" class="mt-3 text-uppercase" native-type="submit" @click="confirmPasswordShow = true">
            Logout Other Browser Sessions
          </base-button>
        </b-col>
      </b-row>
    </card>
    <modal-confirm-password url="/user/other-browser-sessions" :show.sync="confirmPasswordShow" button-text="Logout Other Browser Sessions" title="Logout Other Browser Sessions" @success="getSessions">
      <p>Please enter your password to confirm you would like to logout of your other browser sessions across all of your devices.</p>
    </modal-confirm-password>
  </div>
</template>
<script>
import ModalConfirmPassword from "@/views/User/Profile/ModalConfirmPassword"
import { Http } from "@services"

export default {
  name: 'browser-sessions-card',
  components: { ModalConfirmPassword },
  data() {
    return {
      sessions: [],
      confirmPasswordShow: false,
    }
  },
  mounted: function() {
    this.getSessions();
  },
  computed: {
    hasOtherSessions: function() {
      return (this.sessions.length > 1);
    }
  },
  methods: {
    getSessions: async function() {
      const data = await Http.get('/user/profile');
      this.sessions = data.props.sessions;
    }
  }
}
</script>
