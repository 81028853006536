<template>
  <container-wrapper>
    <template slot="title">
      Clients
    </template>
    <b-card no-body>
      <template v-slot:header>
        Clients
        <b-button class="float-right" size="sm" variant="primary" :to="{ name: 'Add Client'}">+ Add</b-button>
      </template>

      <b-table thead-tr-class="thead-light" striped hover :items="items" :fields="fields" :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <router-link :to="{ path: '/keywords', query: { client_id: data.item.id }}">
            {{ data.item.name }}
          </router-link>
        </template>
        <template v-slot:cell(keyword_projects_count)="data">
          <base-button
            size="sm" type="outline-primary"
            :to="`/keywords?client_id=${data.item.id}`"
          >
            {{ data.value }}
          </base-button>
        </template>

        <template v-slot:cell(page_experience_projects_count)="data">
          <base-button
            size="sm" type="outline-primary"
            :to="`/page-experience?client_id=${data.item.id}`"
          >
            {{ data.value }}
          </base-button>
        </template>
        <template v-slot:cell(options)="data">
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'cog']"/>
              <span class="sr-only">Settings</span>
            </template>
            <b-dropdown-item class="small" :to="{ name: 'Edit Client', params: { client: data.item.id } }">Edit
            </b-dropdown-item>
            <b-dropdown-item-button class="small" variant="danger" @click="deleteClient(data.item)">
              <font-awesome-icon :icon="['fas', 'trash']"/>
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/views/Keywords/pages/Container"
import { Alert, Http } from "@services"

export default {
  components: { ContainerWrapper },
  data () {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'name', label: 'Client Name', sortable: true, sortDirection: 'desc' },
        { key: 'keyword_projects_count', label: 'Keyword Projects', sortable: true, class: 'th-fit text-center' },
        { key: 'page_experience_projects_count', label: 'Page Experience Projects', sortable: true, class: 'th-fit text-center' },
        { key: 'options', label: 'Options', sortable: false, class: 'th-fit p-0 px-2' },
      ]
    }
  },
  mounted () {
    this.loadTableData();
  },
  methods: {
    loadTableData: async function () {
      this.loading = true;
      const { data } = await Http.get(`/clients`);
      this.items = data;
      this.loading = false;
    },
    deleteClient: function (item) {
      Alert.confirm(async () => {
        await Http.delete(`/clients/${ item.id }`);
        await Alert.success({
          title: "Deleted!",
          text: "Successfully deleted!",
        }).fire();
        return this.loadTableData();
      })
        .catch(err => {
          Alert.error({
            title: "Error!",
            text: "Delete failed! This is likely because this client is being used, delete uses before deleting this record.",
          }).fire();
        });
    }
  }
}
</script>
