<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-9 py-lg-9 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="card-profile bg-secondary mt-5">
            <b-card-body class="pt-3 px-5">
              <div class="text-center py-4">
                <img src="/img/brand/logo.png" alt="Cortex Logo" />
              </div>
              <div class="mb-4">
                <h3>Reset Password</h3>
                <p>
                  <small>
                    Reset the password for {{ form.email }}, choose a long secure password to keep your account safe.
                  </small>
                </p>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(() => { form.submit() })">
                  <base-input alternative
                              label="Email"
                              class="mb-3"
                              name="email"
                              :rules="{required: true}"
                              prepend-icon="ni ni-email-83"
                              type="email"
                              placeholder="Email"
                              v-model="form.email"
                  disabled>
                  </base-input>
                  <base-input alternative
                              label="New Password"
                              class="mb-3"
                              name="password"
                              :rules="{required: true}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="form.password">
                  </base-input>
                  <base-input alternative
                              label="Confirm Password"
                              class="mb-3"
                              name="password_confirmation"
                              :rules="{required: true}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Confirm Password"
                              v-model="form.password_confirmation">
                  </base-input>

                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="text-uppercase" :loading="form.busy">Reset Password</base-button>
                    <div class="mt-2">
                      <small><router-link :to="{name: 'Login'}">Or, Go to login.</router-link></small>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Form, Http } from "@services"
export default {
  name: 'reset-password',
  data() {
    const initialData = {
      email: this.$route.query.email || '',
      token: this.$route.params.token || '',
      password: '',
      password_confirmation: ''
    };
    return {
      form: new Form(initialData, async form => {
        const { message } = await Http.post('/reset-password', form);
        return this.$router.replace({name: 'Login', params: { message: message }});
      }, async ({ errors }) => {
        this.$refs.formValidator.setErrors(errors);
      })
    }
  }
};
</script>
<style></style>
