<template>
  <b-list-group flush>
    <b-list-group-item v-for="role in roles"
                       :key="role.key"
                       href="#"
                       :active="value === role.key"
                       @click="$emit('input', role.key)"
    >
      <b-row align-v="center">
        <b-col>
          <h4 class="mb-0">{{ role.name }}</h4>
          <small>{{ role.description }}</small>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
export default {
  name: 'invite-user-roles',
  props: {
    value: null,
    roles: {}
  }
}
</script>
<style scoped>
.list-group-item.active h4 {
  color: #fff;
}
</style>
