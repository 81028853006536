<template>
  <div>
    <card>
      <h3 slot="header" class="mb-0">Users</h3>
      <b-table responsive thead-tr-class="thead-light" :fields="['name', 'email', 'role', 'actions']" :items="users">
        <template v-slot:cell(role)="{item: user}">
          {{ user.role_name }}
        </template>
        <template v-slot:cell(actions)="{ item: user }">
          <span v-if="authUser.id === user.id"><i>You</i></span>
          <div v-else-if="!user.isOwner()">
            <base-button type="outline-primary" size="sm" @click="currentUser = user; showModal=true;">Edit</base-button>
            <base-button type="outline-danger" size="sm" @click="deleteUser(user)">Delete</base-button>
          </div>
        </template>
      </b-table>
      <base-pagination size="sm" align="center" :total="meta.total" :per-page="meta.per_page" v-model="meta.current_page"></base-pagination>

    </card>
    <modal-edit-user :show.sync="showModal" :user="currentUser" @success="getUsers()" button-text="Update">
      <p>Select a new role for {{ currentUser.name }}</p>
    </modal-edit-user>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import { Http } from "@services"
import { mapGetters } from "vuex"
import { User } from "@models"
import ModalEditUser from "@/views/Admin/ManageUsers/UsersCard/ModalEditUser"

export default {
  name: 'users-card',
  components: {
    ModalEditUser,
    FadeTransition
  },
  data() {
    return {
      showModal: false,
      currentUser: {},
      meta: {
        per_page: 15,
        current_page: 1,
        total: 0
      },
      loading: false,
      users: []
    }
  },
  watch: {
    "meta.current_page": function(){
      this.getUsers();
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true
      const { data, meta } = await Http.get(`/manage-users/users?page=${this.meta.current_page}`)
      this.users = User.make(data);
      this.meta = meta;
      this.loading = false
    },
    async deleteUser(user) {
      this.loading = true;
      await Http.delete(`/manage-users/user/${user.id}`);
      await this.getUsers();
    }
  },
  computed: {
    ...mapGetters({
      roles: 'global/roles',
      authUser: 'auth/user'
    })
  }
}
</script>
<style scoped>
.list-group-item.active h4 {
  color: #fff;
}
</style>
