<template>
  <div>
    <card v-if="pendingInvites.length">
      <h3 slot="header" class="mb-0">Pending Invites</h3>
      <b-table responsive thead-tr-class="thead-light" :fields="['email', 'role', 'actions']" :items="pendingInvites">
        <template v-slot:cell(actions)="data">
          <base-button type="outline-danger" size="sm" @click="revokeInvite(data.item)">Revoke</base-button>
        </template>
      </b-table>
    </card>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import { Form, Http } from "@services"
import {mapGetters} from "vuex"

export default {
  name: 'pending-invites',
  components: {
    FadeTransition
  },
  data() {
    return {
      loading: false,
      pendingInvites: []
    }
  },
  mounted() {
    this.getPendingInvites();
  },
  methods: {
    async getPendingInvites() {
      this.loading = true
      this.pendingInvites = await Http.get('/manage-users/pending-invites')
      this.loading = false
    },
    async revokeInvite(invite) {
      this.loading = true;
      await Http.delete(`/manage-users/invite/${invite.id}`);
      await this.getPendingInvites();
    }
  },
  computed: {
    ...mapGetters({
      roles: 'global/roles'
    })
  }
}
</script>
<style scoped>
.list-group-item.active h4 {
  color: #fff;
}
</style>
