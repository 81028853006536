<template>
  <card>
    <h3 slot="header" class="mb-0">Profile Information</h3>
    <b-row align-v="start">
      <b-col sm="4">
        <small>Update your account's profile information and email address.</small>
      </b-col>
      <b-col sm="8">
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <form class="needs-validation"
                @submit.prevent="handleSubmit(() => { form.submit() })">
            <base-input label="Photo" rules="image" name="photo">
              <b-avatar size="6rem" id="profile-form-photo-upload"
                        :src="photoURL" :text="user.initials"></b-avatar>
              <div class="mt-3 d-flex">
                  <base-button type="outline-danger" size="sm" v-if="hasProfilePhoto" @click.prevent="removePhoto">Remove Photo</base-button>
                  <b-form-file accept="image/*" v-model="form.file"  plain></b-form-file>
              </div>
            </base-input>
            <base-input label="Name"
                        name="name"
                        placeholder="Name"
                        rules="required"
                        v-model="form.name">
            </base-input>
            <base-input
                label="Email"
                name="email"
                placeholder="Email"
                rules="required|email"
                v-model="form.email">
            </base-input>
            <div class="d-flex align-items-center">
              <base-button class="float-right text-uppercase" type="outline-primary" native-type="submit" :loading="form.busy">
                Save
              </base-button>
              <fade-transition mode="out-in">
                <div class="text-sm" v-if="form.saved">Saved.</div>
              </fade-transition>
            </div>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
  </card>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import {mapGetters, mapActions} from "vuex"
import axios from "axios"
import _ from "lodash"
import {Form, Http} from "@services"

export default {
  name: 'profile-information-card',
  components: {
    FadeTransition
  },
  data() {
    const user = this.$store.state.auth.user;
    const initialData = {
      name: user.name,
      email: user.email,
      file: null
    };
    return {
      fileURL: null,
      form: new Form(initialData, this.handleSuccess, ({ errors }) => {
        this.$refs.formValidator.setErrors(errors);
      })
    }
  },
  watch: {
    'form.file': function() {
      this.fileURL = null;
      if (this.form.file) {
        this.fileURL = URL.createObjectURL(this.form.file);
      }
    }
  },
  methods: {
    async handleSuccess(form) {
      await this.user.updateProfileInformation(form.export());
      this.form.file = null;
    },
    async removePhoto() {
      this.form.file = null;
      if (this.user.profile_photo_path) {
        this.user.profile_photo_url = null;
        await this.user.removeProfilePhoto();
      }
    }
  },
  computed: {
    hasProfilePhoto: function() {
      return !!(this.fileURL || this.user.hasProfilePhoto());
    },
    photoURL: function() {
      return this.fileURL || this.user.profile_photo_url || null;
    },
    ...mapGetters({
      user: 'auth/user',
    })
  }
}
</script>
<style scoped>
.form-control-file {
  width: auto;
}
</style>
