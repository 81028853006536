<template>
  <card>
    <h3 slot="header" class="mb-0">Invite Users</h3>
    <b-row align-v="start">
      <b-col sm="4">
        <p><small>Add a new team member to Cortex, allowing them to access the application</small></p>
      </b-col>
      <b-col sm="8">
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <form class="needs-validation"
                @submit.prevent="handleSubmit(() => { form.submit() })">
            <base-input label="Email"
                        name="email"
                        placeholder="Email"
                        rules="required|email"
                        type="email"
                        v-model="form.email">
            </base-input>
            <base-input
                        label="Select Role"
                        name="role"
                        placeholder="Role"
                        rules="required"
                        type="password"
                        >
              <invite-user-roles v-model="form.role" :form="form" :roles="roles"/>
            </base-input>
            <div class="d-flex align-items-center">
              <base-button class="float-right text-uppercase" type="outline-primary" native-type="submit" :loading="form.busy">
                Invite
              </base-button>
              <fade-transition mode="out-in">
                <div class="text-sm" v-if="form.saved">Invited.</div>
              </fade-transition>
            </div>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
  </card>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import { Form, Http } from "@services"
import InviteUserRoles from "@/views/Admin/ManageUsers/InviteUser/InviteUserRoles"
import {mapGetters} from "vuex"

export default {
  name: 'invite-user',
  components: {
    InviteUserRoles,
    FadeTransition
  },
  data() {
    const initialData = {
      email: null,
      role: null,
    };
    return {
      form: new Form(initialData, this.submitCallback, ({ errors }) => {
        this.$refs.formValidator.setErrors(errors);
      })
    }
  },
  methods: {
    async submitCallback(form) {
      await Http.post('/manage-users/invite', form);
      form.reset();
      this.$nextTick(() => {
        this.$refs.formValidator.reset();
      });
      this.$emit('invited');
    }
  },
  computed: {
    ...mapGetters({
      roles: 'global/roles'
    })
  }
}
</script>
<style scoped>
.list-group-item.active h4 {
  color: #fff;
}
</style>
