<template>
  <container-wrapper>
    <template slot="title">
      Add Client
    </template>
    <b-card header="Add Client">
      <b-card-text>
        <client-form @success="onSubmit" @failed="onFailed"></client-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/views/Keywords/pages/Container"
import ClientForm from "@/views/Clients/ClientForm"

export default {
  components: {
    ClientForm,
    ContainerWrapper,
  },
  methods: {
    onSubmit: function (data) {
      this.$router.push({ path: '/' });
    },
    onFailed: function (errors) {
    }
  }
}
</script>
