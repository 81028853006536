<template>
  <container-wrapper>
    <template slot="title">
      Edit Client
    </template>
    <b-card header="Edit Client">
      <b-card-text>
        <client-form v-if="!loading" :client="client" @success="onSubmit" @failed="onFailed"></client-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/views/Keywords/pages/Container"
import ClientForm from "@/views/Clients/ClientForm"
import { Http } from "@services"

export default {
  data () {
    return {
      client: null,
      loading: true
    }
  },
  components: {
    ClientForm,
    ContainerWrapper,
  },
  async mounted () {
    const { data } = await Http.get(`/clients/${this.$route.params.client}`);
    this.client = data;
    this.loading = false;
  },
  methods: {
    onSubmit: function (data) {
      this.$router.push({ path: '/' });
    },
    onFailed: function (errors) {
    }
  }
}
</script>
