<template>
  <card>
    <h3 slot="header" class="mb-0">Update Password</h3>
    <b-row align-v="start">
      <b-col sm="4">
        <p><small>Ensure your account is using a long, random password to stay secure.</small></p>
        <p><small>If you don't know your password, such as if you logged in with Google. <br /><router-link :to="{name: 'Forgot Password', params: { email: user.email }}">Click here to request a reset.</router-link></small></p>
      </b-col>
      <b-col sm="8">
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <form class="needs-validation"
                @submit.prevent="handleSubmit(() => { form.submit() })">
            <base-input label="Current Password"
                        name="current_password"
                        placeholder="Current Password"
                        success-message=""
                        rules="required"
                        type="password"
                        v-model="form.current_password">
            </base-input>
            <base-input label="New Password"
                        name="password"
                        placeholder="New Password"
                        success-message=""
                        rules="required"
                        type="password"
                        v-model="form.password">
            </base-input>
            <base-input label="Confirm Password"
                        name="password_confirmation"
                        placeholder="Confirm Password"
                        success-message=""
                        rules="required"
                        type="password"
                        v-model="form.password_confirmation">
            </base-input>
            <div class="d-flex align-items-center">
              <base-button class="float-right text-uppercase" type="outline-primary" native-type="submit" :loading="form.busy">
                Save
              </base-button>
              <fade-transition mode="out-in">
                <div class="text-sm" v-if="form.saved">Saved.</div>
              </fade-transition>
            </div>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
  </card>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex'
import {Form, Http} from "@services"
export default {
  name: 'update-password-card',
  components: {
    FadeTransition
  },
  data() {
    const initialData = {
      current_password: null,
      password: null,
      password_confirmation: null
    };
    return {
      model: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      form: new Form(initialData, this.submitCallback, ({ errors }) => {
        this.$refs.formValidator.setErrors(errors);
      })
    }
  },
  methods: {
    async submitCallback(form) {
      await Http.put('/user/password', form);
      form.reset();
      this.$nextTick(() => {
        this.$refs.formValidator.reset();
      });
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  }
}
</script>
