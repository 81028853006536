<template>
  <div>
    <base-header class="pb-9">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Manage Users</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--9">
      <b-row class="justify-content-center">
        <b-col lg="10" xl="9"  class="card-wrapper">
          <invite-user-card @invited="$refs.pendingInvites.getPendingInvites()" />
          <pending-invites ref="pendingInvites" />
          <users-card />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseHeader from '@/components/BaseHeader';
import ProfileInformationCard from "@/views/User/Profile/ProfileInformationCard"
import UpdatePasswordCard from "@/views/User/Profile/UpdatePasswordCard"
import BrowserSessionsCard from "@/views/User/Profile/BrowserSessionsCard"
import DeleteAccountCard from "@/views/User/Profile/DeleteAccountCard"
import InviteUserCard from "@/views/Admin/ManageUsers/InviteUserCard"
import PendingInvites from "@/views/Admin/ManageUsers/PendingInvitesCard"
import UsersCard from "@/views/Admin/ManageUsers/UsersCard"

export default {
  name: 'home',
  components: {
    UsersCard,
    PendingInvites,
    InviteUserCard,
    BaseHeader,
    RouteBreadcrumb
  }
};
</script>
