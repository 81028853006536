<template>
  <modal v-bind="$attrs" v-on="$listeners">
    <h6 slot="header" class="modal-title">{{ title }}</h6>
    <slot></slot>
    <validation-observer ref="formValidator">
      <form class="needs-validation" @submit.prevent="onSubmit">
        <base-input name="role"
                    label="New Role"
                    rules="required">
          <b-form-select v-model="form.role" :options="roles"></b-form-select>
        </base-input>
      </form>
    </validation-observer>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="closeModal()">Close</base-button>
      <base-button :type="buttonType" class="text-uppercase" :loading="form.busy" @click="onSubmit">{{ buttonText }}</base-button>
    </template>

  </modal>
</template>
<script>
import {Form, Http} from "@services"
import {mapGetters} from "vuex"
import _ from "lodash"

export default {
  name: 'ModalEditUser',
  props: {
    url: String,
    title: {
      type: String,
      default: "Edit User"
    },
    buttonText: {
      type: String,
      default: "Submit"
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    user: {
      type: Object
    }
  },
  watch: {
    user: function() {
      this.form.role = this.user.role
    }
  },
  data() {
    const initialData = {
      role: ''
    };
    return {
      form: new Form(initialData, async form => {
        await Http.patch(`/manage-users/users/${this.user.id}/update-role`, {...form.export() });
        this.$emit('success');
        this.closeModal();
      }, async ({ response, errors }) => {
        this.$emit('failed', response);
        this.$refs.formValidator.setErrors(errors);
      })
    }
  },
  methods: {
    onSubmit: function() {
      this.$refs.formValidator.validate().then(success => {
        if (success) {
          return this.form.submit();
        }
      });
    },
    closeModal: function() {
      this.$emit("update:show", false);
      this.$emit("close");
    }
  },
  computed: {
    roles: function() {
      return _.map(this.globalRoles, function(role) {
        return {
          text: role.name,
          value: role.key
        }
      })
    },
    ...mapGetters({
      globalRoles: 'global/roles'
    })
  }

}
</script>
