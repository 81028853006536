<template>
  <div>
  <card>
    <h3 slot="header" class="mb-0">Delete Account</h3>
    <b-row align-v="start">
      <b-col sm="4">
        <small>Permanently delete your account.</small>
      </b-col>
      <b-col sm="8">
        <div><small>Once your account is deleted, all of its resources and data will be permanently deleted. Before
          deleting your account, please download any data or information that you wish to retain.</small></div>
        <base-button type="danger" class="mt-3 text-uppercase" @click="showConfirmPassword = true">Delete Account</base-button>
      </b-col>
    </b-row>
  </card>
    <modal-confirm-password :show.sync="showConfirmPassword" url="/user" @success="onSuccess" @failed="onFailed" button-type="danger" button-text="Delete Account">
      <p>Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. </p>
      <p>Please enter your password to confirm you would like to permanently delete your account.</p>
    </modal-confirm-password>
  </div>
</template>
<script>
import ModalConfirmPassword from "@/views/User/Profile/ModalConfirmPassword"
import {mapActions} from "vuex"
export default {
  name: 'delete-account-card',
  components: {ModalConfirmPassword},
  data() {
    return {
      showConfirmPassword: false
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    onFailed: function(response) {
      if (response.status === 409) {
        return this.logout().then(() => {
          this.onSuccess();
        })
      }
    },
    onSuccess: function() {
      this.$router.replace({ name: 'Login' });
    }
  }
}
</script>
